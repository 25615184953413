<template>
  <v-sheet class="homeBanner ">
    <banner-one></banner-one>
    <v-sheet>
      <v-card tile height="" class="mt-0 pt-0" elevation="0" >
        <div class="d-flex justify-center" >
          <v-btn link to="/product" :style="$vuetify.breakpoint.mobile ? 'width:100%': ''" style="border-radius: 0 0 25px 25px;" class="font-weight-bold text-h4 mx-auto" x-large color="black" dark>{{$vuetify.lang.t('$vuetify.home.ctaTop')}}</v-btn>
        </div>
      </v-card>
    </v-sheet>

    <banner-two></banner-two>
    <banner-three></banner-three>
    <banner-four></banner-four>
    <banner-five></banner-five>
  </v-sheet>
</template>

<script>
import BannerOne from '@/components/home/h-banner-one'
import BannerTwo from '@/components/home/h-banner-two'
import BannerThree from '@/components/home/h-banner-three'
import BannerFour from '@/components/home/h-banner-four'
import BannerFive from '@/components/home/h-banner-five'
  export default {

    name: 'HomePage',
    data: () => ({
      footer: require('@/assets/footerLogo.svg')
    }),
    mounted  () {
      this.$gtag.event('Open Home', { method: 'View', event_category: 'Page', event_label: 'View', value: 'home'})
    },
    components: {
      BannerOne, BannerTwo,BannerThree, BannerFour,BannerFive
    },
  }
</script>

<style>

</style>
