<template>
  <v-sheet class="black ">
    <v-sheet dark class="black pb-8 main px-0 mx-auto" >
      <v-row class=" px-0 mx-0" >
        <v-col v-if="!$vuetify.breakpoint.mobile" class="pl-1 mx-0">
          <v-img contain :src="heroFive" width="440" style="position:relative; top:-12px;"></v-img>
        </v-col>
        <v-col class=" px-0 mx-0">
          <v-card class="black" elevation="0">
            <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2 mt-6 pt-16 '" class="mx-auto font-weight-bold text-break"><span>{{$vuetify.lang.t('$vuetify.home.heroFourHeader')}}</span></v-card-title>
            <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h6' :'pt-16 text-h4 '" class="mx-auto text-break"><span>{{$vuetify.lang.t('$vuetify.home.heroFourOne')}}</span></v-card-title>
            <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h6' :'pt-16 text-h4 '" class="mx-auto text-break"><span>{{$vuetify.lang.t('$vuetify.home.heroFourTwo')}}</span></v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mobile" class="pl-1 mx-0">
          <v-img contain :src="heroFive" width="440" style="position:relative; top:-12px;"></v-img>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "h-banner-five",
  data: () => ({
    heroFive: require('@/assets/code.svg'),
  }),
}
</script>

<style scoped>

</style>
