import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from '@/translations/en'

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {en },
		current: 'en'
	},
	icons: {
		values: { expand: 'mdi-menu-down' }
	},
	rtl: false,
	breakpoint: { mobileBreakpoint: 680 },
	theme: {
		light: true,
		themes: {
			light: {
				primary: '#ffa337',
				secondary: '#E0E0E0',
				accent: '#BABABA',
				error: '#FF715B',
				info: '#e2f5f5',
				success: '#4cae1f',
				warning: '#DB5461'
			}
		}
	}
})
