import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from "@/views/ContactView";
import ServicesView from "@/views/ServicesView";
import TermsView from '@/views/TermsView';
import CookiesView from '@/views/CookieStatementView';
import NotFound from '@/views/404View';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesView
  },
  {
    path: '/tos',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/product',
    name: 'product',
    component: ServicesView
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
