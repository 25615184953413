import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import Toast from 'vue-toastification'
import logger from './plugins/logger'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import titleMixin from './plugins/titleMixin'

import "vue-toastification/dist/index.css";

Vue.config.productionTip = false
Vue.mixin(titleMixin) // auto set title
Vue.use(VueGtag, {
  config: { id: 'G-1EFRQ6H411' },
  appName: 'queuecat.com',
  pageTrackerScreenviewEnabled: true
}, router)
Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 3,
  newestOnTop: true,
  position: 'top-right',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.7,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
})
Vue.use(VueMeta);
new Vue({
  router,
  logger,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");

