<template>
    <v-bottom-sheet
        v-model="sheet"
        hide-overlay
    >
      <v-sheet
          class="secondary text-center"
          max-height="200px"

      >
        <v-btn
            class="mt-4 mx-4"
            dark
            @click="approve()"
        >
          {{$vuetify.lang.t('$vuetify.cookie.acceptTos')}}
        </v-btn>
        <v-btn
            class="mx-4 mt-4 red"
            @click="close()"
        >
          {{$vuetify.lang.t('$vuetify.cookie.rejectTos')}}
        </v-btn>
        <div class="py-3">
          {{$vuetify.lang.t('$vuetify.cookie.thisIsOurCookiePolicy')}}
          <router-link to="/cookies">{{$vuetify.lang.t('$vuetify.cookie.readMoreHere')}}</router-link>
        </div>
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
export default {
  name: "CookieComponent",
  data: () => ({
    sheet: false,
  }),
  mounted () {
    this.sheet = this.checkTos()
  },
  methods: {
    checkTos () {
      const status = localStorage.getItem('qc_accepted_policy')
      if (status === null) { return true }
      return !status
    },
    approve () {
      this.sheet = false
      localStorage.setItem('qc_accepted_policy', 'true')
    },
    close() {
      window.location.href ='about:blank';
    }
  }
}
</script>

<style scoped>

</style>
