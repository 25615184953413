var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-card',{staticClass:"primary  text-break py-10",attrs:{"tile":"","dark":"","elevation":"0"}},[_c('v-sheet',{staticClass:"primary main  ",attrs:{"tile":""}},[_c('v-row',{staticClass:" mx-0 px-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":!_vm.$vuetify.breakpoint.mobile?6 :12}},[_c('v-card',{staticClass:"primary ",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-break font-weight-bold",class:_vm.$vuetify.breakpoint.mobile ? 'text-h5 ' : 'text-h1 mx-2 px-0'},[_c('span',{},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.contact.bannerTitle')))])]),_c('v-card-title',{staticClass:"text-h2 text-break  mx-2 px-0",attrs:{"dark":""}},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.contact.bannerSubtitle')))])])],1)],1),_c('v-col',{attrs:{"cols":!_vm.$vuetify.breakpoint.mobile? 6 :12}},[_c('v-form',{ref:"contactForm",attrs:{"id":"contactForm"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"label":_vm.$vuetify.lang.t('$vuetify.contact.name'),"name":"name","rules":_vm.rules.inputText,"required":"","background-color":"white","light":"","height":"60","maxlength":"100","rounded":"","dense":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(message))])]}}]),model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.contact.email'),"name":"email","rules":_vm.rules.emailRules,"maxlength":"100","required":"","background-color":"white","light":"","height":"60","rounded":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(message))])]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.contact.subject'),"name":"subject","rules":_vm.rules.inputText,"required":"","background-color":"white","maxlength":"100","light":"","height":"60","rounded":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(message))])]}}]),model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('v-textarea',{staticClass:"mt-4",attrs:{"name":"message","model":"message","filled":"","label":_vm.$vuetify.lang.t('$vuetify.contact.message'),"required":"","rules":_vm.rules.textArea,"maxlength":"350","background-color":"white","light":"","rounded":"","rows":"8","row-height":"15"},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(" "+_vm._s(message))])]}}])}),(_vm.errMsg !== '')?_c('v-layout',{staticClass:"mt-5 mb-5 white--text font-weight-bold",attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")]):_vm._e(),_c('v-layout',{staticClass:"mt-4"},[_c('v-btn',{staticClass:" font-weight-bold",class:_vm.$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4',attrs:{"large":"","color":"black","dark":""},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.contact.sendMessage')))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }