<template>
  <v-sheet>
    <v-card tile class="primary  text-break py-10" dark  elevation="0" >
        <v-sheet tile class="primary main  ">
          <v-row dense class=" mx-0 px-0">
          <v-col :cols="!$vuetify.breakpoint.mobile?6 :12">
            <v-card elevation="0" class="primary ">
              <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h5 ' : 'text-h1 mx-2 px-0'" class="text-break font-weight-bold" ><span class="">{{$vuetify.lang.t('$vuetify.contact.bannerTitle')}}</span></v-card-title>
              <v-card-title class="text-h2 text-break  mx-2 px-0" dark><span >{{$vuetify.lang.t('$vuetify.contact.bannerSubtitle')}}</span></v-card-title>
            </v-card>
          </v-col>
          <v-col :cols="!$vuetify.breakpoint.mobile? 6 :12" >
            <v-form  v-model="valid" id="contactForm" ref="contactForm">
              <v-text-field
                  :label="$vuetify.lang.t('$vuetify.contact.name')"
                  v-model="name"
                  name="name"
                  :rules="rules.inputText"
                  required
                  background-color="white"
                  light
                  height="60"
                  maxlength="100"
                  rounded
                  class="ma-0 pa-0"
                  dense

              >
                <template v-slot:message="{message}">
                  <span class="white--text font-weight-bold"> {{message}}</span>
                </template>
              </v-text-field>
              <v-text-field
                  :label="$vuetify.lang.t('$vuetify.contact.email')"
                  v-model="email"
                  name="email"
                  :rules="rules.emailRules"
                  maxlength="100"
                 required
                  background-color="white"
                  light
                  height="60"
                  rounded
              >

                <template v-slot:message="{message}">
                  <span class="white--text font-weight-bold"> {{message}}</span>
                </template>
              </v-text-field>
              <v-text-field
                  :label="$vuetify.lang.t('$vuetify.contact.subject')"
                  v-model="subject"
                  name="subject"
                  :rules="rules.inputText"
                 required
                  background-color="white"
                  maxlength="100"
                  light
                  height="60"
                  rounded
              >
                <template v-slot:message="{message}">
                  <span class="white--text font-weight-bold"> {{message}}</span>
                </template>
              </v-text-field>
              <v-textarea
                  name="message"
                  model="message"
                  filled
                  :label="$vuetify.lang.t('$vuetify.contact.message')"
                  required
                  :rules="rules.textArea"
                  maxlength="350"
                  background-color="white"
                  class="mt-4"
                  light
                  rounded
                  rows="8"
                  row-height="15"
              >
                <template v-slot:message="{message}">
                  <span class="white--text font-weight-bold"> {{message}}</span>
                </template>
              </v-textarea>
              <v-layout justify-center v-if="errMsg !== ''" class="mt-5 mb-5 white--text font-weight-bold">
                {{errMsg}}
              </v-layout>
              <v-layout class="mt-4">
                <v-btn :class="$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4' " class=" font-weight-bold" large color="black" dark @click="submit">{{$vuetify.lang.t('$vuetify.contact.sendMessage')}}</v-btn>
              </v-layout>
            </v-form>
          </v-col>
          </v-row>
        </v-sheet>
    </v-card>
  </v-sheet>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  name: "ContactView",
  data: () => ({
    valid: false,
    rules: [],
    errMsg: '',
    name: '',
    email: '',
    subject: '',
    message: ''
  }),
  mounted() {
    this.setRules();
    this.$gtag.event('Open Home', { method: 'View', event_category: 'Page', event_label: 'View', value: 'contact'})
  },
  methods: {
    sendEmail() {
      emailjs
          .sendForm(
              "service_3fxb48k",
              "template_13jhgol",
              '#contactForm',
              "user_51Ysf9rBUAdO0WYzmJuRx"
          )
          .then(
              (result) => {
                console.log("SUCCESS!", result.text);
              },
              (error) => {
                console.log("FAILED...", error.text);
              }
          );
    },
    submit() {
      if (this.$refs.contactForm.validate()) {
        try {
          this.sendEmail();
          this.$gtag.event('SendEmail', { method: 'Email', event_category: 'Page', event_label: 'Contact', value: this.email})
        } catch (error) {
          console.log({error});
        }
        this.$toast(this.$vuetify.lang.t('$vuetify.contact.confirmEmail'));
        this.$refs.contactForm.reset()
      }
    },
    cancel() { },
    setRules () {
      this.rules = {
        textArea: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.rules.notEmpty'),
          (v) => (v && v.length <= 350) || this.$vuetify.lang.t('$vuetify.rules.maxLengthThreeFiddy')
        ],
        inputText: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.rules.notEmpty'),
          (v) => (v && v.length >= 3) || this.$vuetify.lang.t('$vuetify.rules.minLength'),
          (v) => (v && v.length <= 100) || this.$vuetify.lang.t('$vuetify.rules.maxLength'),
          (v) => /^[a-z0-9åøæ ']+$/i.test(v) || this.$vuetify.lang.t('$vuetify.rules.invalidCharacters'),
        ],
        emailRules: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.rules.notEmpty'),
          (v) => (v && v.length <= 100) || this.$vuetify.lang.t('$vuetify.rules.maxLength'),
          v => /^(([^<>()=#^&%$!?[\]\\.,;:\s@']+(\.[^<>()=#^&%$!?\\[\].,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$vuetify.lang.t('$vuetify.rules.the email address is invalid'),

        ]
      }
    },
  }
}
</script>

<style >
.v-label {
   color:#8B94A3 !important;
}
.v-label-active {
  color: black !important;
}
.error--text {
  color: black !important;
}
</style>
