<template>
  <v-sheet class="secondary"  >
    <v-sheet class=" secondary d-flex" min-height="750" v-if="!$vuetify.breakpoint.mobile">
      <v-row dense class="main white" >
        <v-col class="secondary" style="border-radius: 0 25px 0 0;" cols="6" >
          <banner-left></banner-left>
        </v-col>
        <v-col class="secondary mt-0 pa-0" cols="6" >
          <banner-right></banner-right>
        </v-col>

      </v-row>
    </v-sheet>
    <v-sheet class="secondary " v-else>
      <v-row class="mx-2">
        <v-col cols="12">
          <v-card class="fill-height" color="secondary" style="border-radius: 0 25px 0 0;" elevation="0">
            <v-card-text style="max-width:400px;"   class="mx-auto">
              <v-card-title :class="$vuetify.breakpoint.mobile ? '' : 'mt-14  pt-14'" class=" black--text text-h2 font-weight-bold text-break">
                <span>{{$vuetify.lang.t('$vuetify.home.heroThreeTitle')}}</span>
              </v-card-title>

              <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h6': 'text-h4  mt-6'" class="mx-auto black--text text-break font-weight-bold mb-4">
                <span>{{$vuetify.lang.t('$vuetify.home.heroThreeSubtitleOne')}}</span>
              </v-card-title>
              <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h6': 'text-h4'" class="mx-auto black--text text-break ">
                <span>{{$vuetify.lang.t('$vuetify.home.heroThreeTextOne')}}</span>
              </v-card-title>

              <v-card-title :class="$vuetify.breakpoint.mobile ? 'text-h6': 'text-h4 mt-16 '" class="mx-auto black--text text-break font-weight-bold mb-2">
                <span>{{$vuetify.lang.t('$vuetify.home.heroThreeSubtitleTwo')}}</span>
              </v-card-title>
              <v-card-title  :class="$vuetify.breakpoint.mobile ? 'text-h6': 'text-h4'" class="mx-auto black--text text-break">
                <span>{{$vuetify.lang.t('$vuetify.home.heroThreeTextTwo')}}</span>
              </v-card-title>
              <v-card-title>
              <v-btn link to="/contact" class="mt-4" style="z-index:10000" x-large color="black" dark>{{$vuetify.lang.t('$vuetify.home.heroThreeShort')}}</v-btn>
              </v-card-title>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col cols="12">
          <v-card  elevation="0" >
            <v-card-title><span class="text-h4 font-weight-bold">{{$vuetify.lang.t('$vuetify.home.pageExample')}}</span></v-card-title>
            <v-card-text  style="border-radius: 0 0 25px 25px;"  class="pt-14">
              <v-img contain :width="$vuetify.breakpoint.width - 20" :src="heroThree" ></v-img>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
import BannerLeft from '@/components/home/b-three-left'
import BannerRight from '@/components/home/b-three-right'
export default {
  name: "h-banner-three",
  components:{
    BannerLeft, BannerRight
  },
  data: () => ({
    heroThree: require('@/assets/todayView.svg'),
  }),

}
</script>

<style scoped>

</style>
