<template>
  <v-sheet  class="secondary fill-height pl-0 ml-0 " elevation="0">
    <v-card-text class="pl-0 ml-0 mt-16 pt-5 ">
      <v-card-title  class="ml-0 mt-0 pl-0 mr-14 black--text text-h2 font-weight-bold text-break">
        <span class="">{{$vuetify.lang.t('$vuetify.home.heroThreeTitle')}}</span>
      </v-card-title>

      <v-card-title class="ml-0 mt-9 pl-0 mr-14 black--text text-h4 text-break font-weight-bold">
        <span>{{$vuetify.lang.t('$vuetify.home.heroThreeSubtitleOne')}}</span>
      </v-card-title>
      <v-card-title   class="ml-0  pl-0 mr-14 black--text text-h4 text-break ">
        <span>{{$vuetify.lang.t('$vuetify.home.heroThreeTextOne')}}</span>
      </v-card-title>

      <v-card-title  class="ml-0  pl-0 mr-14 black--text text-h4 text-break font-weight-bold mt-8">
        <span>{{$vuetify.lang.t('$vuetify.home.heroThreeSubtitleTwo')}}</span>
      </v-card-title>
      <v-card-title  class="ml-0  pl-0 mr-14 black--text text-h4 text-break">
        <span>{{$vuetify.lang.t('$vuetify.home.heroThreeTextTwo')}}</span>
      </v-card-title>
      <v-btn link to="/contact" style="z-index:50000"  class="mt-4 font-weight-bold text-h4" large color="black" dark>{{$vuetify.lang.t('$vuetify.home.heroThreeCta')}}</v-btn>
    </v-card-text>
  </v-sheet>
</template>

<script>
export default {
  name: "b-three-left.vue"
}
</script>

<style scoped>

</style>
