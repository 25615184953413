<template>
  <v-sheet class="secondary" height="290px">
    <v-img v-if="!$vuetify.breakpoint.mobile" :src="heroFour" contain height="300" style="position: relative;left:-80px;bottom:-10px;z-index:10"></v-img>
    <v-img v-else :src="heroFour" contain height="300" style="position: relative;bottom:0px;left:60px;z-index:1"></v-img>
  </v-sheet>
</template>

<script>
export default {
  name: "h-banner-four",
  data: () => ({
    heroFour: require('@/assets/black cat.svg'),
  }),
}
</script>

<style scoped>

</style>
