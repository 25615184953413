<template>
  <v-sheet class="primary ml-0 pl-0">
    <v-card class="primary main pl-0 pr-0" dark tile elevation="0" >
      <v-card-text :class="$vuetify.breakpoint.mobile ? '' : 'ml-0 pl-0'">
        <v-row dense class="no-gutters">
          <v-col :cols="$vuetify.breakpoint.width < 960 ? 12 :6">
            <v-card elevation="0" class="primary py-16 mr-4">
              <v-card-title :class="$vuetify.breakpoint.mobile ? 'pa-1 text-h5 pb-0 mb-0' : 'text-h1 pa-0'" class="text-break font-weight-bold" ><span class="">{{$vuetify.lang.t('$vuetify.home.titleSplitOne')}}</span></v-card-title>
              <v-card-title :class="$vuetify.breakpoint.mobile ? 'pa-1 text-h5 mb-5 pt-0 mt-0' : 'text-h1 pa-0'" class="text-break font-weight-bold" ><span class="">{{$vuetify.lang.t('$vuetify.home.titleSplitTwo')}}</span></v-card-title>
              <v-card-title class="text-break pa-0 mt-5" :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h3'" dark><span >{{$vuetify.lang.t('$vuetify.home.subtitleOne')}}</span></v-card-title>
              <v-card-title v-if="$vuetify.breakpoint.mobile" style="height:150px;">
                <router-link to="/product">
                  <img style="position:absolute;bottom:-38px; z-index: 100"  height="300" :src="logo" >
                </router-link>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" md="6" sm="12" classes="flex-column d-flex" >
            <v-sheet class="primary mx-10 px-10" height="176px">
              <router-link to="/product">
                  <v-img style="position:absolute; bottom:-26px;right:60px;z-index: 100"  :style="$vuetify.breakpoint.width < 960?'right:0px' :''" contain height="376" href="/" :src="logo" ></v-img>
              </router-link>
            </v-sheet>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "h-banner-one.vue",
  data: () => ({
    logo: require('@/assets/LogoCat.svg'),
  }),
}
</script>

<style scoped>

</style>
