<template>
  <v-card  min-width="400" elevation="0" class=" mx-0 px-0"  style="border-radius: 0 0 25px 25px;">
    <v-card-title class="pt-12 pl-0 ml-0"><span class="ml-8 text-h4 font-weight-bold">{{$vuetify.lang.t('$vuetify.home.pageExample')}}</span></v-card-title>
    <v-card-text   class="pt-0 mt-0 white">
      <v-img  contain :src="heroThree" ></v-img>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "b-three-right",
  data: () => ({
    heroThree: require('@/assets/todayView.svg'),
  }),
}
</script>

<style scoped>

</style>
