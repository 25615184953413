import Vue from 'vue'
import Vuex from 'vuex'

const BUILD_NO = 3
const VERSION = "1.0"
const TRANS_PACK = 11
const IN_DEBUG = true
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appVersion: `v. ${VERSION}.${BUILD_NO} t.${TRANS_PACK}`,
    serviceCatTimer: null,
    inDebug: IN_DEBUG
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
