<template>
  <v-sheet dark class=" black "  >
    <v-sheet class="main black mt-8 mb-10 ">
      <v-row dense class="px-0">
        <v-col cols="12" sm="12" xl="6" md="6" xs="12" >
          <v-card class="black ml-0 px-0" dense>
            <v-card-title class="pt-8 text-h4 text-break">{{$vuetify.lang.t('$vuetify.home.footer')}}  <span v-if="inDebug">&nbsp; - {{buildNo}}</span></v-card-title>
            <v-card-subtitle class="text-h4 text-break">{{$vuetify.lang.t('$vuetify.home.footerSubtitle')}}</v-card-subtitle>

            <v-card-title class="pt-6 text-h6 text-break">{{$vuetify.lang.t('$vuetify.home.footerLongText')}}</v-card-title>

            <v-card-actions class="pt-6 text-h6 text-break">
              <v-btn link to="/tos" plain>{{$vuetify.lang.t('$vuetify.btn1')}}</v-btn>
              <v-btn link to="/cookies"  plain>{{$vuetify.lang.t('$vuetify.btn2')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" class=" d-flex " >
          <v-sheet class="black d-flex align-content-center justify-content-center">
            <v-img :src="footer" contain class=" mx-16 align-content-center" ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "h-footer",
  data: () => ({
    footer: require('@/assets/footerLogo.svg'),

  }),
  computed: {
    buildNo () {
      return this.$store.state.appVersion
    },
    inDebug () {
      return this.$store.state.inDebug
    }
  }
}
</script>

<style scoped>

</style>
