<template>
  <v-sheet class="">
    <v-card tile class="mt-0 pt-0 d-flex justify-center text-break" elevation="0" >
      <v-card-text class="main my-0 py-0">
        <v-row class="mt-4 ">
          <v-col cols="6" v-if="!$vuetify.breakpoint.mobile">
            <v-img style="position:relative;bottom:-20px;left:-12px" :src="heroTwo"></v-img>
          </v-col>
          <v-col :cols="!$vuetify.breakpoint.mobile?6 :12" class="align-self-center" >
            <v-card-title class="black--text" ><span :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h2' " class="font-weight-bold text-break" >{{$vuetify.lang.t('$vuetify.home.heroTwoTitle')}}</span></v-card-title>
            <v-card-subtitle class="mt-1 black--text text-break" ><span  :class="$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4' ">{{$vuetify.lang.t('$vuetify.home.heroTwoSubTitle')}}</span></v-card-subtitle>
            <v-sheet class="mx-0">
              <v-btn  link to="/product" :class="$vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4' " class=" font-weight-bold" large color="black" dark>{{$vuetify.lang.t('$vuetify.home.heroTwoCta')}}</v-btn>
            </v-sheet>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.mobile">
            <v-img style="position:relative;bottom:-13px" :src="heroTwo"></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  name: "h-banner-two",
  data: () => ({
    heroTwo: require('@/assets/hero2.svg'),
  }),
}
</script>

<style scoped>

</style>
