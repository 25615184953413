import { en } from 'vuetify/lib/locale'

export default {
	...en,
	btn1: 'Terms of service',
	btn2: 'Cookies & privacy',

	home: {
		titleSplitOne: 'Just hits,',
		titleSplitTwo: 'no hisses',
		subtitleOne: 'Manage your traffic peaks and never worry about bottlenecks again',
		ctaTop: 'Get started',
		heroTwoTitle: 'The all-in-one solution for your traffic',
		heroTwoSubTitle: 'From NFT drops to world tour tickets, we have you covered. Our virtual waiting room keeps your servers cool and your customers hot.',
		heroTwoCta: 'How it works',
		pageExample: 'Peak traffic example',
		heroThreeTitle: 'You are in control',
		heroThreeSubtitleOne: 'No expensive server upgrades',
		heroThreeTextOne: 'QueueCat automatically takes over when your servers start to enter their red zone and keeps your customers engaged',
		heroThreeSubtitleTwo: 'Put the fun back in funnel',
		heroThreeTextTwo: 'We offer a fully customizable interface with your customers and a team ready to help you implement it',
		heroThreeCta: 'Get a quote',
		heroThreeShort:'Get a quote',
		heroFourHeader: 'Easy setup. Smooth response',
		heroFourOne: 'Using a direct link from your website, visitors can enter the queue when any overload appears - by using either server-side or client-side JavaScript',
		heroFourTwo: 'QueueCat supports integrations with payment tools, e-commerce platforms, native mobile apps and many more',
		footer: 'queuecat.com',
		footerSubtitle: '2022 - All rights reserved',
		footerLongText: 'QueueCat is a virtual waiting room system that protects your website and keeps your visitors engaged'
	},

	menu: {
		home: 'Home',
		services: 'Product',
		contact: 'Contact us'
	},

	services: {
		bannerTitle:'The extra room you always needed',
		bannerSubtitle: 'QueueCat is a virtual waiting room system that keeps your website safe and your visitors engaged',
		getInTouch: 'Get in touch',
		secondHeader: 'More traffic? No worries',
		secondText: 'Ready to launch a stellar product to the world and you expect more guests than usual? Our customizable queue solution will help you deliver on your promises without disrupting the customer experience.',
		thirdHeader: 'No bots allowed',
		thirdText: "QueueCat always keeps watch, so those random DDoS attacks don’t interfere with your plans or your fans. Swipe left all those unsolicited bots and prioritize your real customers"
	},

	contact: {
		name: 'Your name',
		email:'Your e-mail',
		subject:'Subject',
		message:'How can we help you?',
		sendMessage:'Submit',
		bannerTitle: 'Get a quote from us',
		confirmEmail:'Your request has been submitted. We will get back to you as soon as possible',
		bannerSubtitle: "Tell us a bit about yourself and we’ll make sure you get the offer that fits you best"
	},

	tos:{
		title:'QueueCat Terms and Conditions'
	},

	cookie:{
		acceptTos:'Accept Cookies',
		rejectTos:'Reject Cookies and close website',
		thisIsOurCookiePolicy:'We use minimum cookies and similar technologies to improve your experience with us.',
		readMoreHere:'See more details here'
	},

	cookies:{
		title:'Cookies policy & privacy'
	},

	notfound:{
		thisPageWasNotFound:"Well, something is not right. The page you were looking for doesn't exist"
	},

	rules: {
		lengthUnder250: 'You have to use a maximum of 250 characters',
		notEmpty: 'This field is mandatory',
		minLength: 'A minimum of 3 characters have to be used in this field',
		maxLength:'A maximum of 100 characters have to be used in this field',
		maxLengthThreeFiddy:'Your text has to be of maximum 350 characters in length',
		minThreeChars: 'A minimum of 3 characters have to be used',
		equalOrGreaterThan0: '_Must be equal or greater than 0',
		greaterThan0: '_Must be greater than 0',
		invalidCharacters:'You can only use numerical characters or letters a-z',
		maxFiveKChars: 'Whooa, there! You have to use a maximum of 5000 characters',
		pctCannotBeOver100: '_Cannot be over 100%',
		'the email address is invalid': 'The e-mail address is invalid'
	}

}

