<template>
  <v-card tile class="mainHead" elevation="0">
    <v-card-title class="text-h2 my-4 font-weight-bold">{{$vuetify.lang.t('$vuetify.cookies.title')}}</v-card-title>
    <v-card-text>
      <div class="mx-auto text-h6 mb-8">
        <span class="font-weight-bold">What are cookies?</span> <br>
        Cookies are small text files consisting of letters and numbers. These are sent from Queue-it’s or our partners’ web servers and stored on your device as described below. We use Persistant cookies, files that remain until you erase them, or they expire.
        <br>
        <span class="font-weight-bold">Why cookies are necessary</span> <br>
        Some of our pages actually need cookies to work, others simply make our website experience better for you.
        <br>
        <span class="font-weight-bold">Cookie purpose</span> <br>
        The table below details the purpose of the cookies used on the site and duration of each.
        <br>
      </div>
      <p class="text-h3">
        <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="15"
            tile
            hide-default-footer
        ></v-data-table>
      </p>
      <div class="mx-auto text-h6 mb-8">
        <span class="font-weight-bold">What kind of information are we collecting?</span> <br>
        It is important to us that you are aware of what type of information we collect about you and how it is collected. In order to provide our services when you request a quote from us via a form on our website, we need to collect certain personal information. You may directly or indirectly give us information about yourself by manifesting your interest in using our products.
        <br>
        <span class="font-weight-bold">What do we do with your information?</span> <br>
        We always collect personal information responsibly and with your privacy in mind. We need to process your information when you are visiting our website and want to receive a quote for our virtual waiting room service, we need your email and contact information to be able to contact you and offer you a tailored solution. We keep your data only as long as necessary to fulfil our obligations towards you and as required by statutory retention periods. We may also use your data to communicate relevant information regarding used or similar services. If you do not wish to receive such communication, please send an email to contact@queuecat.com. We will NOT sell your personal details and information to third parties.
        <br>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CookieStatementView",
  data: () => ({
    headers: [
      { text: 'Name', value: 'cookie' },
      { text: 'Domain', value: 'domain' },
      { text: 'Type', value: 'type' },
      { text: 'Purpose', value: 'purpose' },
      { text: 'Duration', value: 'duration' },
    ],
    data: [
      {
        cookie: '_ga',
        domain: '.queuecat.com',
        duration: '2Y',
        type: 'Necessary',
        purpose:'This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session,\n' +
            'campaign data and keep track of site usage for the site\'s analytics report. The cookies store\n' +
            'information anonymously and assigns a randomly generated number to identify unique visitors.'
      },
      {
        cookie: '_ga_XXXXXXX',
        domain: '.queuecat.com',
        duration: '2Y',
        type: 'Necessary',
        purpose:'This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session,\n' +
            'campaign data and keep track of site usage for the site\'s analytics report. The cookies store\n' +
            'information anonymously and assigns a randomly generated number to identify unique visitors.'
      },
      {
        cookie: 'qc_accepted_policy',
        domain: '.queuecat.com',
        duration: '2Y',
        type: 'Necessary',
        purpose:'Stores the user\'s cookie consent state for the current domain'
      }
    ]
  }),
}
</script>

<style scoped>

</style>
